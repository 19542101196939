import debounce from "lodash/debounce";

type CustomNode = {
  tag: string;
  props: Record<string, any>;
  children: (CustomNode | string)[];
};

const h = (tag: string, props: Record<string, any> = {}, ...children: (CustomNode | string)[]): CustomNode => ({
  tag,
  props,
  children,
});

const setStyles = (element: HTMLElement, styles: Record<string, string>): void => {
  Object.assign(element.style, styles);
};

const createElement = (customNode: CustomNode | string): HTMLElement => {
  if (typeof customNode === "string") {
    return document.createTextNode(customNode.trim()) as unknown as HTMLElement;
  }

  const element = document.createElement(customNode.tag);

  Object.entries(customNode.props).forEach(([name, value]) => {
    if (name === "style") {
      setStyles(element, value as Record<string, string>);
    } else {
      element.setAttribute(name, value);
    }
  });

  customNode.children.forEach((child) => {
    element.appendChild(createElement(child));
  });

  return element;
};

// Promo banner component
const PromoBanner = ({ isMobile }: { isMobile: boolean }): CustomNode => {
  const resetMarginAndPadding = {
    margin: "0",
    padding: "0",
  };

  const commonStyles = {
    backgroundColor: "#ffb4d2",
    padding: "1rem",
    display: "block",
    cursor: "pointer",
    textDecoration: "none",
  };

  const commonTextStyles = {
    color: "#000",
    margin: "0",
    fontSize: "1rem",
    lineHeight: "1.6",
    fontFamily: `"TWK Lausanne", sans-serif`,
  };

  const saleTagStyles = {
    padding: "0.25rem 0.375rem 0 0.375rem",
    textTransform: "uppercase",
    borderRadius: "0.75rem",
    backgroundColor: "#ff3",
    marginRight: "0.25rem",
    lineHeight: "1.6",
    display: "inline-block",
  };

  if (isMobile) {
    return h(
      "p",
      { style: resetMarginAndPadding },
      h(
        "a",
        {
          rel: "prerender",
          href: "/quiz",
          style: commonStyles,
        },
        h(
          "span",
          {
            style: {
              ...commonTextStyles,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            },
          },
          h(
            "span",
            {
              style: {
                margin: "0 0.25rem",
                textWrap: "balance",
                textAlign: "left",
              },
            },
            "Get started with the most affordable immunotherapy",
          ),
          h(
            "span",
            {
              style: {
                ...saleTagStyles,
                padding: "0.25rem 0.625rem 0 0.625rem",
                borderRadius: "62.5rem",
                marginRight: "0.375rem",
                display: "flex",
                alignItems: "center",
                flexShrink: "0",
                gap: "0.25rem",
              },
            },
            h("span", { style: { fontWeight: "bold" } }, "Sale:"),
            h("span", { style: { margin: "0", textDecoration: "line-through" } }, "$49"),
            h("span", { style: { margin: "0", position: "relative" } }, "$9.99"),
          ),
        ),
      ),
    );
  }

  return h(
    "p",
    { style: resetMarginAndPadding },
    h(
      "a",
      {
        rel: "prerender",
        href: "/quiz",
        style: commonStyles,
      },
      h(
        "span",
        {
          style: {
            ...commonTextStyles,
            display: "block",
            textAlign: "center",
          },
        },
        h("span", { style: saleTagStyles }, "Fall sale"),
        h(
          "span",
          {
            style: {
              margin: "0 0.25rem",
              textWrap: "balance",
            },
          },
          "Get started with the most affordable immunotherapy for only",
        ),
        h("span", { style: { margin: "0 0.25rem", textDecoration: "line-through" } }, "$49"),
        h("span", { style: { margin: "0 0.25rem", position: "relative" } }, "$9.99"),
      ),
    ),
  );
};

const addPromoBanner = (): void => {
  try {
    const excludedPaths = ["/ac", "/epipenrx", "/auviq", "/prescription-drops", "/mnps"];
    if (excludedPaths.includes(window.location.pathname)) {
      return;
    }

    const mainWrapper = document.getElementsByClassName("main-wrapper")?.[0] as HTMLElement;
    if (!mainWrapper) {
      console.error("Failed to create promo banner element: Main wrapper not found.");
      return;
    }

    const mobileMediaQuery = window.matchMedia("(max-width: 767px)");

    const renderBanner = (): void => {
      const existingBanner = document.getElementById("promo-banner");
      if (existingBanner) {
        existingBanner.remove();
      }

      const promoBanner = createElement(PromoBanner({ isMobile: mobileMediaQuery.matches }));
      promoBanner.id = "promo-banner";
      mainWrapper.prepend(promoBanner);
    };

    renderBanner();

    mobileMediaQuery.addEventListener("change", debounce(renderBanner, 1000, { leading: true, trailing: true }));
  } catch (e) {
    console.error("Promo banner error:", e);
  }
};

export { addPromoBanner };
