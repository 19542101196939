import type { ValuesType } from "utility-types";
import kebabCase from "lodash/kebabCase";
import merge from "lodash/merge";
import { PageModel, RuleModel } from "./models";

import { LoggerService } from "~/services/logger";
import { View } from "~/components/organisms/SignInSignUpResetPassword/types";

// Static service, don't change it during runtime
class Redirects extends Map<RuleModel["destination"], RuleModel> {
  // TODO: Support __1, __2 with 1, 2 as variables
  public readonly TEMPLATE: "__" = "__" as const;

  private static _existsError(rule: RuleModel): void {
    LoggerService.error(
      `Redirect to ${rule.destination} already exists, please modify this rule instead of creating new one.`,
    );
  }

  public add(rule: RuleModel): Redirects {
    if (this.has(rule.destination)) {
      Redirects._existsError(rule);
      return this;
    }

    this.set(rule.destination, rule);
    return this;
  }
}

const passwordResetInterceptor: ValuesType<RuleModel["interceptors"]> = (
  currentPage: PageModel,
  newPage: PageModel,
): PageModel => {
  return currentPage.path === "/login/password-reset"
    ? merge(newPage, { query: { view: kebabCase(View.PASSWORD_RESET_CONFIRM) } })
    : newPage;
};

const passwordRecoveryInterceptor: ValuesType<RuleModel["interceptors"]> = (
  currentPage: PageModel,
  newPage: PageModel,
): PageModel => {
  return currentPage.path === "/login/password-recovery"
    ? merge(newPage, { query: { view: kebabCase(View.PASSWORD_RESET) } })
    : newPage;
};

const careManagerPages: string[] = [
  "/allergy-treatment/care-manager",
  "/allergy-treatment/allergy-consultation",
  "/consultation/checkout",
  "/pages/consultation",
];

const RedirectsService: Redirects = new Redirects();

RedirectsService.add(
  new RuleModel({
    destination: "/demographics/finish",
    sources: [
      "/demographics/disqualified",
      "/demographics/legacy-finish",
      "/questionnaire/allergy-drops/finish",
      "/questionnaire/allergy-drops/success",
      "/demographics/allergy-immunotherapy/finish",
      "/demographics/allergy-immunotherapy/success",
    ],
  }),
)
  .add(
    new RuleModel({
      destination: "/login",
      sources: ["/login/password-recovery", "/login/password-reset"],
      interceptors: [passwordResetInterceptor, passwordRecoveryInterceptor],
    }),
  ) // edge case
  .add(
    new RuleModel({
      destination: "/my-curex/test-results",
      sources: ["/account/results/__", "/account/results/details/__", "/my-curex/results-and-docs"],
    }),
  ) // edge case
  .add(new RuleModel({ destination: "/my-curex", sources: ["/account"] }))
  .add(new RuleModel({ destination: "/my-curex/billing", sources: ["/my-curex/payment-methods"] }))
  .add(new RuleModel({ destination: "/my-curex/household", sources: ["/my-curex/kid-profiles"] }))
  .add(
    new RuleModel({
      destination: "/demographics/allergy-immunotherapy",
      sources: [
        "/quiz",
        "/free-quiz",
        "/checkout-v2",
        "/checkout",
        "/allergy-consultation",
        "/demographics/allergy-consultation",
        "/demographics/allergy-immunotherapy/v2",
        "/demographics/allergy-test/order",
        "/demographics/medical-history/free",
        "/demographics/basic-care",
        "/allergy-drops/checkout",
      ],
    }),
  )
  .add(
    new RuleModel({
      destination: "/demographics/auviq",
      sources: ["/checkout-auviq"],
    }),
  )
  .add(
    new RuleModel({
      destination: "/",
      sources: [
        "/epipenrx",
        "/about-curex",
        "/simple",
        "/faq",
        "/allergyprevention",
        "/homepage",
        "/lp/allergy-care-seo-optimized",
        "/register",
        "/register/kit",
        "/register/kit/success",
      ],
    }),
  )
  .add(
    new RuleModel({
      destination: "/allergy-test",
      sources: [
        "/allergy-test/dc",
        "/premium-allergy-testing",
        "/premium-allergy-test",
        "/plans/allergy-therapy-plan",
        "/pages/buy-allergy-test",
        "/pages/test",
        "/test",
        "/allergy-treatment/allergy-test",
        ...careManagerPages,
      ],
    }),
  )
  .add(
    new RuleModel({
      destination: "/conditions/allergies",
      sources: ["/allergy-clinic", "/science", "/about/allergy-science"],
    }),
  )
  .add(new RuleModel({ destination: "/conditions/eczema", sources: ["/eczema"] }))
  .add(new RuleModel({ destination: "/curex-vs-shots", sources: ["/shots", "/archive/curex-vs-shots-old"] }))
  .add(new RuleModel({ destination: "/immunotherapy-101", sources: ["/immunotherapy-faq", "/immunotherapy-new"] }))
  .add(new RuleModel({ destination: "/kids", sources: ["/curex-kids"] }))
  .add(new RuleModel({ destination: "/legal/informed-consent", sources: ["/consent-to-telehealth"] }))
  .add(new RuleModel({ destination: "/legal/notice-of-privacy-practices", sources: ["/privacy-notice"] }))
  .add(new RuleModel({ destination: "/legal/privacy-policy", sources: ["/privacy"] }))
  .add(new RuleModel({ destination: "/legal/states-where-we-operate", sources: ["/states"] }))
  .add(new RuleModel({ destination: "/legal/terms", sources: ["/terms"] }))
  .add(new RuleModel({ destination: "/pharmacy", sources: ["/pharmacy-category"] }))
  .add(
    new RuleModel({
      destination: "/plans/prevention-plus",
      sources: [
        "/allergy-immunotherapy",
        "/allergy/allergy-shots",
        "/allergy-treatment/allergy-immunotherapy",
        "/immunotherapy-options",
        "/prevention-plus",
        "/plans/prevention-at-home",
        "/pages/allergy-drops",
        "/treatment",
        "/treatment/checkout",
        "/treatment/plan",
        "/questionnaire/allergy-drops",
        "/questionnaire/allergy-drops/plan",
      ],
    }),
  )
  .add(
    new RuleModel({
      destination: "/pricing",
      sources: ["/pricing-old", "/prices", "/prices-v0", "/prices-sep-2022"],
    }),
  )
  .add(
    new RuleModel({
      destination: "/my-curex/lab-requisitions",
      sources: ["/reqs"],
    }),
  )
  .add(
    new RuleModel({
      destination: "https://email-assets.public.getcurex.com/documents/guide-for-new-members.pdf",
      sources: ["/guide"],
    }),
  )
  .add(new RuleModel({ destination: "https://partner.getcurex.com", sources: ["/about-us", "/about"] })); // edge case
// .add(new RuleModel({ destination: "/blog-posts/__", sources: ["/post/__", "/allergy/__"] })); // edge case

export { RedirectsService };
